<template>
  <div class="report-view task-time-sheet-report">
    <div class="viewEdit ttrInfo">
      <!-- start date picker toggle -->
      <b-card class="d-flex">
        <div class="calendar justify-content-between mt-0">
          <p class="task-timesheet">
            Scheduled Report
          </p>
        </div>
      </b-card>
      <!-- end date picker toggle -->

      <!-- start Filters & export -->
      <div class="card-filter-section mt-0">
        <b-row>
          <b-col
            cols="8"
            lg="8"
            class="d-block d-lg-flex align-items-center justify-content-start flex-wrap"
          >
            <p class="filter-title">
              Filters:
            </p>

            <div
              v-if="userInfo && userInfo.role == 'SA'"
              class="w-lg-100"
            >
              <custom-dropdown
                v-model="createdByList"
                :options="filterUserOptions"
                label="Created By"
                multiple
              />
            </div>

            <div>
              <custom-dropdown
                v-model="occurrence"
                :options="OccurrenceOption"
                :search-box="false"
                label="Occurrence"
              />
            </div>
            <div> <option-drop-down v-model="perPage" /></div>

          </b-col>
          <b-col
            cols="4"
            lg="4"
            class="d-flex justify-content-end"
          >

            <button
              class="apply-filter filterRedesign mr-1"
              @click="applyFilter()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign mr-1"
              @click="clearFilter()"
            >
              Clear
            </button>
          </b-col>
        </b-row>
      </div>
      <!-- end Filters & export -->

      <div class="report-task-section">
        <list-loader
          v-if="loader && scheduledReportList.length == 0"
          :fields="fields"
        />
        <div v-else-if="scheduledReportList.length">
          <div class="time-sheet-list  schedule-list">
            <b-table
              responsive
              :items="scheduledReportList"
              :fields="fields"
              show-empty
              class="scrollable-element tableDesigns mastertblColor non-project-table"
            >
              <template #cell(name)="data">
                <div>
                  {{ data.item.name }}
                </div>
              </template>

              <template #cell(report_type)="data">
                <div>
                  {{ data.item.report_type == 'resource_availability' ? 'Resource Availability Report' : 'Timesheet Report'
                  }}
                </div>
              </template>

              <template #cell(created_by)="data">
                <div>
                  {{ data.item.created_by.full_name
                  }}
                </div>
              </template>
              <template #cell(last_sent)="data">
                <div>
                  {{ DDMMYYYYHHMMSSformat(data.item.last_sent)
                  }}
                </div>
              </template>
              <template #cell(is_active)="data">
                <b-form-checkbox
                  v-model="data.item.is_active"
                  class="custom-control-success"
                  name="check-button"
                  size="md"
                  switch
                  @change="changeStatus(data.item)"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </template>
              <template #cell(action)="data">
                <span>
                  <feather-icon
                    v-b-tooltip.hover.top="'Edit'"
                    icon="EditIcon"
                    role="button"
                    class="mr-1 text-dark"
                    size="18"
                    @click="edit(data.item)"
                  />
                  <feather-icon
                    v-b-tooltip.hover.top="'Delete'"
                    icon="Trash2Icon"
                    role="button"
                    class="text-danger"
                    size="18"
                    @click="deleteConfirmation(data.item.id)"
                  />
                </span>
              </template>

            </b-table>

          </div>
        </div>
        <div
          v-else
          class="w-100"
        >
          <img
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </div>
        <!-- pagination section -->
        <div class="emp-list p-0">
          <Pagination
            v-if="totalCount > 0"
            :current-page="currentPage"
            :per-page="perPage"
            :total-count="totalCount"
            class="report-pagination"
            @currentPage="currentPage = $event"
          />
        </div>
        <!-- pagination section complete here -->
      </div>
    </div>
    <AddEditScheduledReport
      :data="data"
      :from="from"
      :is-schedular-modal-open-prop="isSchedularModalOpen"
      @updateSchedularModalOpen="updateSchedularModalOpen"
    />
  </div>
</template>

<script>
import ListLoader from '@/components/loaders/table-loading.vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import AddEditScheduledReport from './scheduleReports/AddEditScheduledReport.vue'

export default {
  name: 'HeaderBlock',
  components: {
    ListLoader,
    customDropdown,
    AddEditScheduledReport,
  },

  data() {
    return {
      loader: false,
      occurrence: null,
      isSchedularModalOpen: false,
      data: [],
      from: 'resource_availability',
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px' },
        },
        {
          key: 'report_type',
          label: 'Report',
          sortable: false,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px' },
        },
        {
          key: 'occurrence',
          label: 'occurrence',
          sortable: false,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px', textAlign: 'start' },
        },
        {
          key: 'last_sent',
          label: 'Last Sent',
          sortable: false,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px', textAlign: 'start' },
        },
        {
          key: 'created_by',
          label: 'Created By',
          sortable: false,
          thStyle: { width: '400px' },
          tdStyle: { width: '400px', textAlign: 'start' },
        },
        {
          key: 'is_active',
          label: 'Active',
          sortable: false,
        },
        {
          key: 'action',
          label: 'Action',
          sortable: false,
          thStyle: { width: '100px' },
          tdStyle: { width: '100px' },
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      scheduledReportList: [],
      createdByList: [],
      users: [],
      /* pagination variable */
      totalCount: 0,
      currentPage: 1,
      perPage: 25,
    }
  },
  computed: {
    /* occurrence option list */
    OccurrenceOption() {
      return [
        { label: 'weekly', value: 'weekly' },
        { label: 'monthly', value: 'monthly' },
      ]
    },
    /* created by user option list */
    filterUserOptions() {
      return this.$store.state.app.usersList.filter(user => (user.is_resource_managing || user.role == 'SA'))
    },
  },
  watch: {
    currentPage() {
      this.getScheduledReport()
    },
    perPage() {
      if (this.currentPage != 1) {
        this.currentPage = 1
      } else {
        this.getScheduledReport()
      }
    },
  },

  mounted() {
    this.createdByList.push(this.userInfo.id)
    /* if user list not in store then call user list api */
    if (!this.$store.state.app.usersList || !this.$store.state.app.usersList.length) {
      this.userList()
    }
    /* get Scheduled Report */
    this.getScheduledReport()
  },
  methods: {

    /* apply filter function */
    applyFilter() {
      this.getScheduledReport()
    },

    /* clear filter function */
    clearFilter() {
      this.occurrence = null
      this.createdByList = []
      this.currentPage = 1
      this.perPage = 25
      this.createdByList.push(this.userInfo.id)
      this.applyFilter()
    },

    /* get Scheduled Report function */
    async getScheduledReport() {
      this.loader = true
      const input = {
        occurrence: this.occurrence,
        created_by: this.createdByList,
        page: this.currentPage,
        per_page: this.perPage,
      }
      const response = await this.getHTTPPostResponse(
        'scheduled-report/list',
        input,
        false,
      )

      if (response && response.data) {
        const { data } = response
        this.scheduledReportList = data.scheduled_report
        this.totalCount = data.count
      }
      this.loader = false
    },

    /* confirmation for update status  function */
    async changeStatus(scheduledReport) {
      const label = scheduledReport.is_active ? 'Active' : 'Deactive'
      const title = `Are you sure ${label} Scheduled Report ?`
      const confirmation = await this.conformationAlert(false, title, label)

      if (confirmation.isConfirmed) {
        this.updateStatus(scheduledReport)
      } else {
        this.scheduledReportList = []
        this.getScheduledReport()
      }
    },

    /* update Scheduled Report status function */
    async updateStatus(scheduledReport) {
      this.loader = true
      const input = {
        id: scheduledReport.id,
        is_active: scheduledReport.is_active,
      }
      const response = await this.getHTTPPostResponse(
        'scheduled-report/status-update',
        input,
        false,
      )

      if (response && response.data) {
        const { data } = response
        this.scheduledReportList = data.scheduled_report
      }
      this.loader = false
    },

    /* delete Confirmation after that  delete Scheduled Report function  */
    async deleteConfirmation(id) {
      const value = await this.conformationAlert(true)
      if (value.isConfirmed === true) {
        const response = await this.getHTTPDeleteResponse(
          `scheduled-report/delete/${id}`,
          {},
          true,
        )
        if (response && response.status === 200) {
          this.getScheduledReport()
        }
      }
    },

    /* edit */
    edit(data) {
      this.data = data
      this.isSchedularModalOpen = true
      this.from = data.report_type
    },

    /* update */
    updateSchedularModalOpen(data) {
      this.isSchedularModalOpen = data
      if (!data) {
        this.data = null
      }
      this.getScheduledReport()
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/approvals.css";
@import "../../assets/scss/component-css/report.css";

.schedule-list {
  max-height: calc(100vh - 230px);
  overflow-y: auto;

  table thead {
    position: sticky;
    top: -0.1px;
    z-index: 5;
  }
}

.custom-switch {
  text-align: end;
  z-index: 0 !important;
}
 .tooltip-inner {
  z-index: 9999 !important;
}
</style>
